$(function() {
	
	/*[wow][:]*/
	wow = new WOW({
		offset: 0,
		live: false,
		mobile: false
	})
	wow.init();
	/*[wow][;]*/
	
	/*[nav][:]*/
	function hideMenu() {
		$('.navigation').removeClass('navigation_active');
		$('.header__toggle').removeClass('header__toggle_active');
	};
	$(document).on('keydown', function(e) {
		if (e.which === 27) {
			e.preventDefault();
			hideMenu();
		};
	});
	$(document).on('click', function(e) {
		if ($(e.target).closest('.header__navigation').length === 0) {
			hideMenu();
		};
	});
	/*[nav][;]*/
	
	/*[back][:]*/
	$('.cta._back').hover(function(e) {
		var $this = $(this);
		setTimeout(function() {
			$this.addClass('is-hover');
		}, 400);
	}, function(e) {
		var $this = $(this);
		$this.addClass('out-hover');
		setTimeout(function() {
			$this.removeClass('is-hover out-hover');
		}, 200);
	});
	/*[back][;]*/
	
	/*[validator][:]*/
	$.validator.addMethod("letters", function(value, element) {
		return this.optional( element ) || /^[a-zа-я\s]+$/i.test(value);
	}, "Letters only please");
	$.validator.addMethod("letters_n", function(value, element) {
		return this.optional( element ) || /^[a-zа-я0-9\s]+$/i.test(value);
	}, "Letters and numbers only please");
	$.validator.addMethod("letters_np", function(value, element) {
		return this.optional(element) || /^[a-zа-я0-9\-.,()'"\s]+$/i.test(value);
	}, "Letters, numbers and punctuation only please");
	$.validator.addMethod("phone", function(value, element) {
		return this.optional(element) || /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/i.test(value)
	}, "Please specify a valid phone number");
	
	$.validator.addClassRules('_email', {email: true});
	/* $.validator.addClassRules('_phone', {phone: true});
	$.validator.addClassRules('_letters', {letters: true});
	$.validator.addClassRules('_letters_n', {letters_n: true});
	$.validator.addClassRules('_letters_np', {letters_np: true}); */
	/*[validator][;]*/
	
	/*[feedback][:]*/
	var feedback_form = $('#feedback-form');
	feedback_form.validate({
		submitHandler: function() {

			$.ajax({
				type: 'post',
				dataType: 'json',
				url: feedback_form.attr('action'),
				data: feedback_form.serialize(),
				success: function(data) {
					$('.modal._send').fadeIn();
					feedback_form.get(0).reset();
				},
				error: function() {
					$('.modal._error').fadeIn();
				}
			});
		},
		errorPlacement: function(error, element) {
			return false;
		}
	});
	$('.btn-close').on('click', function(e) {
		$(this).parents('.modal').fadeOut();
		return false;
	});
	/*[feedback][;]*/

	console.log(new Date().getFullYear());

	$('#current-year').text(new Date().getFullYear());
});

$(function() {
  // Services Toggle
  $('.services__toggle').on('click', function(e) {
    e.preventDefault();

    var $service = $(this).parents('.services__item');

    if($service.is('.services__item_active')) {
      $service.find('.services__hidden').slideUp(300, function() {
        $service.removeClass('services__item_active');
      });
    } else {
      $service.siblings('.services__item_active').find('.services__hidden').slideUp(300, function() {
        $(this).parents('.services__item_active').removeClass('services__item_active');
      });
      $service.find('.services__hidden').slideDown(300);
      $service.addClass('services__item_active');
    }
  });

  // Services Link
  $('.services__link').on({
    'mouseenter': function() {
      $(this).parents('.services__sub').addClass('services__sub_active');
    },
    'mouseleave': function() {
      $(this).parents('.services__sub').removeClass('services__sub_active');
    }
  });

  // Nav
  $('.header__toggle').on('click', function(e) {
    e.preventDefault();
      console.log("asd");
    $(this).toggleClass('header__toggle_active');
    $('.navigation').toggleClass('navigation_active');
  });

  var $header = $('.header');

  $(window).on({
    scroll: function() {
      $(window).scrollTop() > 140 ? $header.addClass('header_sticky') : $header.removeClass('header_sticky');
    }
  });

  // Service Tabs

  $('.tab-content__hidden').on('click', function() {
    var active = 'tab-content_active';
    var hidden = 'tab-content__body';
    var $service = $(this).parents('.tab-content');

    if(!$service.is('.' + active)) {
      $service.siblings('.' + active).find('.' + hidden).slideUp(300, function() {
        $(this).parents('.' + active).removeClass(active);
        $(this).removeAttr('style');
      });
      $service.find('.' + hidden).slideDown(300, function() {
        $(this).removeAttr('style');
      });
      $service.addClass(active);
    }
  });
  $('.tab-nav__link').on('click', function(e) {
    e.preventDefault();

    var active = 'tab-content_active';
    var activeNav = 'tab-nav__item_active';
    var hidden = 'tab-content__body';
    var $service = $('.tab-content').eq($(this).parent().index());

    $('.' + activeNav).removeClass(activeNav);
    $(this).parent().addClass(activeNav);

    if(!$service.is('.' + active)) {
      $service.siblings('.' + active).find('.' + hidden).hide(0, function() {
        $(this).parents('.' + active).removeClass(active);
        $(this).removeAttr('style');
      });
      $service.find('.' + hidden).show(0, function() {
        $(this).removeAttr('style');
      });
      $service.addClass(active);
    }
  });

	var hash = location.hash.replace('#','');
	if(hash != ''){
		$('.tab-'+hash).click();
		$('html, body').animate({ scrollTop: $('#service-tabs').offset().top - 200}, 1000);
	}

});

$(function() {
	var $allVideos = $("iframe[src^='http://www.youtube.com']");
	$allVideos.each(function() {
		$(this)
			.data('aspectRatio', this.height / this.width)
			.removeAttr('height')
			.removeAttr('width');

	});
	$(window).resize(function() {
		$allVideos.each(function() {
			var $el = $(this);
			$el
				.width($el.parent().width())
				.height($el.parent().width() * $el.data('aspectRatio'));
		});
	}).resize();

});