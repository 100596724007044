
$(function() {
if ($('.promo').length && !device.tablet() && !device.mobile()) {
    /*[fn][:]*/
    function fnFill() {
        var length = colors.length - 1;
        return colors[randomInt(0, length)];
    };

    function fnResize() {

        w_width  = $(window).outerWidth();
        w_height = $(window).outerHeight();
        h_height = $('.header').outerHeight();
        f_height = $('.footer').outerHeight();

        v_width  = w_width;
        v_height = w_height - f_height;

        vh_width  = v_width  / 2;
        vh_height = v_height / 2;

        x_indent = vh_width - gh_width;
        y_indent = vh_height - gh_height;

        radius = Math.min(v_width, v_height) + 280;

        $('.promo').css({
            width: v_width,
            height: v_height
        });

        $('body').removeClass('loading');

        stage.setAttrs({
            width: v_width,
            height: v_height
        });

        group[0].setAttrs({

        });

        frame = [];
        $( ".promo .scene a" ).each(function() {
            frame.push([Math.floor($(this).offset().left + offset[0]), Math.floor($(this).offset().top + offset[1])]);
        });
    };

    function fnPoints() {
        function point() {
            return (-10 + Math.random() * 45);
        };

        x1 = point(); y1 = point();
        x2 = point(); y2 = point();
        x3 = point(); y3 = point();

        a = fnDistance(x1, y1, x2, y2);
        c = fnDistance(x2, y2, x3, y3);
        b = fnDistance(x3, y3, x1, y1);

        P = (a + b + c) / 2;
        S = Math.sqrt(P * (P - a) * (P - b) * (P - c));

        if (a < 10 || b < 10 || c < 10 || S < 45) {
            fnPoints();
        } else {
            return [
                x1, y1,
                x2, y2,
                x3, y3
            ];
        };
    };
    function fnShuffle(o) {
        for (var j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
        return o;
    };
    function fnRad2Grad(num) {
        return num * (180 / Math.PI);
    };
    function fnGrad2Rad(num) {
        return num * (Math.PI / 180);
    };
    function randomInt(min, max) {
        return (
            Math.round(
                min + Math.random() * (max - min)
            )
        );
    };
    function randomFloat(min, max) {
        return (
        min + Math.random() * (max - min)
        );
    };
    function fnDistance(x0, y0, x1, y1) {
        return Math.sqrt(
            Math.pow((x0 - x1), 2) +
            Math.pow((y0 - y1), 2)
        );
    };
    /*[fn][;]*/

    /*[var][:]*/
    var stage,
        radius,
        layer = [],
        group = [],
        image = [],
        frame = [],
        crumbs = [],
        scraps = [],
        source = [],
        colors = [],
        tweens = [],
        w_width, w_height,
        x_indent, y_indent,
        x_middle, y_middle,
        v_width,  vh_width,
        v_height, vh_height,
        g_width,  gh_width,
        g_height, gh_height;

    frame = [];

    if($( window ).width() >= 1920) {
        source = $('#stage').data('source-1920').split(';');
        offset = [-30, -365];
    }
    else {
        source = $('#stage').data('source-1280').split(';');
        offset = [-30, -275];
    }


    colors = randomColor({
        count: 30,
        hue: 'monochrome',
        luminosity: 'light'
    });

    g_width   = 1920;
    g_height  = 1080;
    gh_width  = g_width / 2;
    gh_height = g_height / 2;
    x_middle  = gh_width;
    y_middle  = gh_height;

    stage = new Konva.Stage({
        container: 'stage'
    });
    var mousePos;
    var image_hover = false;

    layer[0] = new Konva.Layer()

    group[0] = new Konva.Group({
        width: g_width,
        height: g_height
    });
    /*[var][;]*/

    /*[item][:]*/
    $('a').hover(function(e) {
        image_hover = true;
    }, function(e) {
        image_hover = false;
    });

    $('.promo .item').hover(function(e) {
        var $this = $(this),
            index = $this.index(),
            tween = tweens[index];
        imagesAnimate(index);
    }, function(e) {

        var $this = $(this),
            index = $this.index(),
            tween = tweens[index];
        imagesReverce(index);
    });
    $('.promo .item').on('click', function(e) {
        //return false;
    });
    /*[item][;]*/

    /*[events][:]*/
    $(window).on('resize', function(e) {
        fnResize();
    }).trigger('resize');

    $(window).on('mousemove', function(event) {
        mousePos = {
            x: event.pageX,
            y: event.pageY
        };

    });
    /*[events][;]*/

    /*[loading][:]*/
    function loadImage(src, callback) {
        var img = new Image();

        img.src = src;
        img.onload = function() {
            callback(img);
        };
    };
    function loadImages(index, images, callback) {
        var img = new Image(),
            size = images.length - 1;

        img.src = images[index];
        img.onload = function() {
            image.push(img);

            if (index < size) {index += 1;
                loadImages(index, images, callback);
            } else if (index === size) {
                callback(image);
            };
        };
    };
    /*[loading][;]*/

    /*[image][:]*/
    function createParticleImage(image, _frame) {
        var x0, y0,
            x1, y1,
            x2, y2,
            row = 0,
            col = 0,
            line = 0,
            grid = 8,
            item = [],
            in_row = 0,
            output = [],
            i_width  = 0,
            i_height = 0,
            c_width  = 0,
            c_height = 0,
            c_amount = 0;

        in_row = grid * 2;
        i_width  = image.width;
        i_height = image.height;
        gd_width  = g_width  * 2;
        gd_height = g_height * 2;
        c_amount = Math.pow(grid, 2);
        c_width  = Math.floor(i_width / grid);
        c_height = Math.floor(i_height / grid);

        item[0] = new Konva.Line({
            closed: true,
            listening: false,
            scale: {x: 0, y: 0},
            fillPatternImage: image
        });

        for (var n = 0; n < c_amount; n++) {
            if (n > 0) col += 1;

            x0 = col * c_width;

            if (Math.round(x0 + c_width) > i_width) {
                x0 = 0;
                col = 0;
                row += 1;
            };

            y0 = row * c_height;

            var randAngle  = Math.random();
            var randRadius = Math.random()*300;
            x1 = v_width/2 + Math.cos(randAngle)*randRadius - 150;
            y1 = v_height/2 + Math.sin(randAngle)*randRadius - 150;

            var randAngle  = Math.random();
            var randRadius = Math.random()*300;
            x2 = v_width/2 + Math.cos(randAngle)*randRadius - 150;
            y2 = v_height/2 + Math.sin(randAngle)*randRadius - 150;


            item[1] = item[0].clone({
                x: x1, y: y1,
                points: [
                    0, 0,
                    0, c_height,
                    1, c_height,
                    c_width, 1,
                    c_width, 0
                ],
                rotation: randomInt(0, 360),
                fillPatternOffset: {x: x0, y: y0}
            });

            item[2] = item[0].clone({
                x: x2, y: y2,
                points: [
                    c_width, 0,
                    c_width, 0,
                    c_width, c_height,
                    0, c_height,
                    0, c_height
                ],
                rotation: randomInt(0, 360),
                fillPatternOffset: {x: x0, y: y0}
            });

            output.push({
                item: item[1],
                x0: x0, y0: y0,
                x1: x1, y1: y1,
                paused: false,
                direction : Math.random() * Math.PI * 2,
                speed: Math.random()*0.5+0.1,
                rotation: Math.random() < 0.5 ? -1 : 1,
                direction: Math.random() * Math.PI * 2
            },{
                item: item[2],
                x0: x0, y0: y0,
                x1: x2, y1: y2,
                paused: false,
                direction : Math.random() * Math.PI * 2,
                speed: Math.random()*0.5+0.1,
                rotation: Math.random() < 0.5 ? -1 : 1,
                direction: Math.random() * Math.PI * 2
            });

            group[0].add(
                item[1].cache(),
                item[2].cache()
            );

            TweenMax.set(item[1], {
                konva: {
                    scale: 0.5,
                    zIndex: 0,
                    opacity: 0.5
                }
            });
            TweenMax.set(item[2], {
                konva: {
                    scale: 0.5,
                    zIndex: 0,
                    opacity: 0.5
                }
            });
        };

        output = fnShuffle(output);
        crumbs.push(output);
    };
    function createParticleImages(array) {
        for (key in array) {
            createParticleImage(array[key], frame[key]);
        };
    };

    function imagesAnimate(index) {
        if(crumbs[index]) {
            for (i = 0; i < crumbs[index].length; i++) {
                data = crumbs[index][i];
                data.paused = true;

                _item = data.item;
                _item_x = data.x0;
                _item_y = data.y0;

                _frame = frame[index];
                _frame_x = _frame[0];
                _frame_y = _frame[1];

                x = _item_x + _frame_x;
                y = _item_y + _frame_y;
                TweenMax.fromTo(data.item, 1, {
                },{
                    konva: {
                        x: x,
                        y: y,
                        scale: 1,
                        zIndex: 1000,
                        opacity: 1,
                        rotation: 360 * Math.floor(data.item.rotation() / 360)
                    }
                });
            }
        }
    }

    function imagesReverce(index) {
        for (j = 0; j < crumbs[index].length; j++) {
            data = crumbs[index][j];
            data.paused = false;
            _item = data.item;
            _item_x = data.x1;
            _item_y = data.y1;

            _frame = frame[index];
            _frame_x = _frame[0];
            _frame_y = _frame[1];

            x = _item_x + _frame_x + 320;
            y = _item_y + _frame_y + 125;

            TweenMax.fromTo(data.item, 2, {
            },{
                konva: {
                    x: _item_x,
                    y: _item_y,
                    scale: 0.5,
                    zIndex: 0,
                    opacity: 0.5
                },
                ease: Expo.easeOut
            });
        }
    }

    function updatePosition() {
        for (i = 0; i < crumbs.length; i++) {
            for (j = 0; j < crumbs[i].length; j++) {
                if (!crumbs[i][j].paused) {
                    var data = crumbs[i][j];
                    var triangle = crumbs[i][j].item;
                    var triangle_x = triangle.x();
                    var triangle_y = triangle.y();

                    //If mousepos exists, change the direction to face the mousepos
                    speedMult = 3;

                    if(!image_hover && mousePos) {
                        var angleRad = Math.atan2(mousePos.y - triangle_y, mousePos.x - triangle_x);
                        const y = (Math.sin(data.direction)*10 + Math.sin(angleRad));
                        const x = (Math.cos(data.direction)*10 + Math.cos(angleRad));

                        speedMult = 10 + 5 * (1 - (Math.abs(angleRad - data.direction)))/5;

                        data.direction = Math.atan2(y, x);

                        //speedMult = 15;
                    }

                    //Redraw triangle on other side of screen if it goes off the edge
                    if (triangle_x < 0) {
                        triangle_x += w_width;
                    }
                    else if (triangle_x > w_width) {
                        triangle_x -= w_width;
                    }
                    if (triangle_y < 0) {
                        triangle_y += w_height;
                    }
                    else if (triangle_y > w_height) {
                        triangle_y -= w_height;
                    }

                    // Moving along triangle's direction
                    var addX = Math.cos(data.direction)*data.speed * speedMult;
                    var addY = Math.sin(data.direction)*data.speed * speedMult;

                    TweenMax.set(triangle, {
                        konva: {
                            x: (triangle_x + addX),
                            y: (triangle_y + addY),
                            rotation: '+=' + data.rotation,
                        }
                    });
                }
            }
        }
        window.requestAnimationFrame(updatePosition);
    }

    function rotateTriangle(triangle){
        triangle.vertices[0] = rotatePoint(triangle.vertices[0], triangle.rotation, triangle.clockwise);
        triangle.vertices[1] = rotatePoint(triangle.vertices[1], triangle.rotation, triangle.clockwise);
        triangle.vertices[2] = rotatePoint(triangle.vertices[2], triangle.rotation, triangle.clockwise);
    }

    function rotatePoint(vertice, rotation, clockwise){
        var s = Math.sin(rotation);
        var c = Math.cos(rotation);

        if(clockwise){
            var newx = vertice[0] * c - vertice[1] * s;
            var newy = vertice[0] * s + vertice[1] * c;
        }
        else{
            var newx = vertice[0] * c + vertice[1] * s;
            var newy = -vertice[0] * s + vertice[1] * c;
        }
        return [newx,newy];
    }
    /*[image][;]*/

    /*[nav][:]*/
    function hideMenu() {
        $('.navigation').removeClass('navigation_active');
        $('.header__toggle').removeClass('header__toggle_active');
    };
    $(document).on('keydown', function(e) {
        if (e.which === 27) {
            e.preventDefault();
            hideMenu();
        };
    });
    $(document).on('click', function(e) {
        if ($(e.target).closest('.header__navigation').length === 0) {
            hideMenu();
        };
    });
    /*[nav][;]*/

    // Nav
    $('.header__toggle').on('click', function(e) {
        e.preventDefault();
        $(this).toggleClass('header__toggle_active');
        $('.navigation').toggleClass('navigation_active');
    });

    var $header = $('.header');

    /*[wow][:]*/
    wow = new WOW({
        offset: 0,
        live: false,
        mobile: false
    })
    wow.init();
    /*[wow][;]*/

    loadImages(0, source, function(image) {
        createParticleImages(image);
        updatePosition();
    });

    layer[0].add(group[0]);

    if(typeof(layer[1]) !== 'undefined') {
        stage.add(
            layer[0],
            layer[1]
        );
    } else {
        stage.add(
            layer[0]
        );
    }
}
});